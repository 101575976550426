export const CONNEXION_QUERY = `query ($username: String!, $password: String!){
  loginUser(username: $username, password:$password ){
    status
    message
    authentificated_user {
      idTtoken
      accessToken
      refreshToken
   },
   user {
    username
    userConfirmed
    userSub
    name
    given_name
    family_name
    email
    picture
    gender
    address{
      street_address
      locality
      region
      postal_code
      country
    }
    phone_number
    city_of_residence
    additional_infos
    description
    groups
    birthdate
    contrib_cwf_balance
 }
 errors{
    param
    msg
  }
}
}
`
