import React, { Component, Fragment } from 'react';
import './../../assets/css/multiple.css';
import './../../assets/css/dashboard.css';
import './../../assets/css/modal.css';
import './../../assets/css/main.css';
import './../../assets/css/annex.css';
import './../../assets/css/crow.css';
import './../../assets/css/multiple.css';
import './../../assets/css/modal.css';
import './../../assets/fa/fa.css';
import './../../assets/css/card.css'; 
import './../../assets/css/responsive.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { withRouter } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import DataTable from 'react-data-table-component';
import { SEARCH_USER_INFOS_QUERY } from '../../query/querysearchuser';
import { BACK_OFFICE_URL } from '../../Url';
import axios from 'axios';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

class SearchUser extends Component {
    constructor(props) {

        super(props);
        this.state = {
            dataValue: [],
            searchValue: '',
            showCheckingModal: false,
            showRejectModal: false,
            criteria: 'Billet non lisible',
            tmp_data: '',
            details_data: [],
            test: [],
        }
        this.data = [];
        this.handleShowValidationModal = this.handleShowValidationModal.bind(this);
        this.handleValidationTicketClick = this.handleValidationTicketClick.bind(this);
        this.handleCloseCheckingModal = this.handleCloseCheckingModal.bind(this);
        this.handleshowRejectModal = this.handleshowRejectModal.bind(this);
        //this.handleRejetReservationClick = this.handleRejetReservationClick.bind(this);
        this.handleSearchValueChange = this.handleSearchValueChange.bind(this);
        this.handleChangeCriteria = this.handleChangeCriteria.bind(this);
        this.handleCloseRejectModal = this.handleCloseRejectModal.bind(this);
    }

    handleChangeCriteria(event) {
        this.setState({ criteria: event.target.value })
    }

    handleSearchValueChange(event) {
        this.setState({ searchValue: event.target.value })
    }

    // handleRejetReservationClick(event) {
    //     event.preventDefault();
    //     var VAR = JSON.stringify({
    //             "travel_details" : {
    //                  "id_travel": this.state.tmp_data,
    //                  "ticket_rejected_reason": this.state.criteria
    //             }
    //     });
    //     console.log(VAR)
    //     //this.rejectReservationRequest(REJECT_TICKET_QUERY, VAR)
    // }

    // rejectReservationRequest = async (query, variables) => {
    //     try {
    //         const response = await axios.post(BACK_OFFICE_URL,
    //             { query, variables },
    //             {
    //                 'headers':
    //                 {
    //                     'Authorization': this.idToken
    //                 }
    //             }
    //         );
    //         // Set the data to the state
    //          console.log(response.data.data)   
    //         if (response.data.data.rejectTravelTicket.status === 200) {
    //             alert('Le billet a bien été rejeté !')
    //             window.location.reload(false);
    //         } else {
    //             alert('Echec lors du décodage')
    //         }

    //     } catch (error) {
    //         // If there's an error, set the error to the state
    //         this.setState(() => ({ error }))
    //         alert('error in sending')
    //     }
    // }

    handleCloseCheckingModal(event, id_proposal) {
        this.setState(
            {
                showCheckingModal: false
            }
        )
    }

    handleValidationTicketClick(event) {
        event.preventDefault();
        var VAR = JSON.stringify({
            "travel_details" : {
                "id_travel": this.state.tmp_data,
                "ticket_number": this.state.searchValue
           }
        });
        //this.validateTicket(VALIDATE_TICKET_QUERY, VAR)
    }

    validateTicket = async (query, variables) => {
        try {
            const response = await axios.post(BACK_OFFICE_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );
            // Set the data to the state

            if (response.data.data.validateTravelTicket.status === 200) {
                alert('Le Billet a été validé!')
                window.location.reload(false);
            } else {
                alert('Echec lors du décodage')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('error in sending')
        }
    }

    handleShowValidationModal(event, data) {
        event.preventDefault();
        this.setState({
            showCheckingModal: true,
            details_data: data.element,
            test: data.element.Attributes
        })
    }

    handleCloseRejectModal() {
        this.setState(
            { showRejectModal: !this.state.showRejectModal }
        )
    }

    handleshowRejectModal(event, id_travel) {
        event.preventDefault();
        this.setState({
            showRejectModal: !this.state.showRejectModal,
            tmp_data: id_travel
        }
        )
    }

    Userlist(e){
        e.preventDefault();
        var VAR = JSON.stringify({
            "search_user" : {
                "attribute_name": this.state.criteria,
                "attribute_value": this.state.searchValue
           }
        });
        this.getUserList(BACK_OFFICE_URL, this.idToken, VAR)
    }

    getUserList = async (url, idToken, variables) => {
        try {
            const response = await axios.post(url,
                { "query": SEARCH_USER_INFOS_QUERY, "variables" : variables },
                {
                    'headers':
                    {
                        'Authorization': idToken
                    }
                }
            );
            this.data = response.data.data.searchUsers.users
            const tmp = []
            this.data.forEach(element => {
                tmp.push({
                    element,
                    name: element.Attributes.find(elt => elt.Name === 'name') ? element.Attributes.find(elt => elt.Name === 'name').Value : '',
                    // Enabled: element.Enabled === "true" ? 'Activé': 'Désactivé',
                    // MFAOptions: element.MFAOptions,
                    // UserCreateDate: element.UserCreateDate,
                    // UserLastModifiedDate: element.UserLastModifiedDate,
                    // UserStatus: element.UserStatus,
                    // Username: element.Username
                });
            });
            this.setState({ dataValue: tmp });
        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            console.log(error)
        }
    }

    componentWillMount() {
        this.idToken = getCookieConsentValue('idToken');
        //this.getUserList(BACK_OFFICE_URL, this.idToken);
    }


    render() {
        const columns = [
            {
                name: 'Titre de transport',
                selector: 'url',
                sortable: true,
                button: true,
                cell: row => <div>
                    {
                        <div>
                            <a class="link color-blue" onClick={(e) => {this.handleShowValidationModal(e, row)}}> Cliquer ici pour afficher plus d'informations </a>
                        </div>
                    }
                </div>

            },
            {
                name: 'Nom du voyageur',
                selector: 'name',
                sortable: true,
                button: true,
                cell: row => <div>
                    {
                        <div>
                            <a> {row.name} </a>
                        </div>
                    }
                </div>

            }

        ];

        
        return (
            <Fragment>
                <div className="app-layout">
                    <div className="app-content content">
                        <div className="content-overlay"></div>
                        <div className="content-wrapper">
                            <div className="content-body">
                                <Modal show={this.state.showCheckingModal} onHide={this.handleCloseCheckingModal} centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Informations sur l'utilisateur</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="form-group">
                                            {
                                                
                                                this.state.dataValue === undefined || this.state.dataValue === null|| this.state.dataValue === "null" || this.state.dataValue === "undefined" || this.state.dataValue === [] || this.state.dataValue === '' ? '':
                                                <div>
                                                <span>Compte actif:  {this.state.details_data.Enabled} </span> <br/>
                                            <span>Date de création du compte: { moment.unix(this.state.details_data.UserCreateDate/1000).format('DD/MM/YYYY')}</span> <br/>
                                            <span>Date de dernière modification du compte: { moment.unix(this.state.details_data.UserLastModifiedDate/1000).format('DD/MM/YYYY')}</span> <br/>
                                            <span>Status du compte: {this.state.details_data.UserStatus}</span> <br/>
                                            <span>UserName: {this.state.details_data.Username}</span> <br/>
                                            {this.state.details_data === undefined || this.state.details_data === null || this.state.details_data === "null" || this.state.details_data === "undefined" || this.state.details_data === [] || this.state.details_data === '' ? '':  
                                            this.state.test.map((value, index) => {     
                                                return <div key={index} >
                                                {value.Name === 'picture' ? <div className="avatar" style={{ backgroundImage: `url(${value.Value})` }}> </div> : value.Name === 'identities' ? "": value.Name +":"+ value.Value}
                                                    <br/>
                                                </div>    
                                                })}
                                            </div>
                                            }
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button className="btn btn-outline-primary" onClick={this.handleCloseCheckingModal}>
                                            OK
                                        </button>
                                    </Modal.Footer>
                                </Modal>

                                <div className="container-fluid">
                                    <div className="row match-height">
                                        <div className="col-sm-3">
                                            <div className="card">
                                                <div className="card-body">
                                                    <label for="exampleFormControlTextarea1">Critère de recherche</label>
                                                    <select className="form-control" value={this.state.criteria} onChange={this.handleChangeCriteria}>
                                                        <option>sub</option>
                                                        <option value="given_name">given name</option>
                                                        <option value="family_name" >family name</option>
                                                        <option>name</option>
                                                        <option value="phone_number">phone number</option>
                                                        <option>email</option>

                                                    </select>

                                                    <span>Valeur</span>
                                                    <input type="text" className="form-control" id="travelFirm" placeholder="ID Ticket" value={this.state.searchValue} onChange={this.handleSearchValueChange} required="true" />
                                                    <button className="btn btn-primary btn-block mt-3" id="travelFirm" onClick={(e) => this.Userlist(e)}> Rechercher</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col align-left">
                                            <div className="card">
                                                <div className="card-body">
                                                    <DataTable
                                                        title="Liste des billets à valider"
                                                        columns={columns}
                                                        data={this.state.dataValue}
                                                        pagination
                                                        selectableRowsComponent
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>                                
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default withRouter(SearchUser);