import React from "react";
import { BrowserRouter, Route, Redirect } from "react-router-dom";

import Connexion from './../pages/connexion/connexion';
import Reporting from '../pages/reporting/reporting';
import Infosvisu from '../pages/InfosVisu/infosvisu';
import VerifID from '../pages/verifID/verifID';
import VerifTicket from '../pages/verifTicket/verifticket';
import Header from '../components/header/header';
import SearchUser from '../pages/searchUser/searchUser';
import Footer from '../components/footer/footer';

function RouterOutlet() {
  return (
    <BrowserRouter>
      <div>
        
        <Route exact path="/" component={goConnexion} />
        {/* <Route path="/reporting" component={goReporting} />
        <Route path="/globalvisu" component={goGlobalVisu} /> */}
        <Route path="/verifAirportTicket" component={goVerifTicket} />
        <Route path="/verifID" component={goVerifID} />
        <Route path="/searchUser" component={goSearchUser} />
      </div>
    </BrowserRouter>
  );
}

function goConnexion() {
    return (
      <div>
        <Connexion />
        <Footer/>
      </div>
    );
  }

  function goReporting() {
    return (
      <div>
        <Header/>
        <Reporting />
        <Footer/>
      </div>
    );
  } 
  
  function goGlobalVisu() {
    return (
      <div>
        <Header/>
        <Infosvisu />
        <Footer/>
      </div>
    );
  }

  function goVerifTicket() {
    return (
      <div>
        <Header/>
       <VerifTicket />
       <Footer/>
      </div>
    );
  }

  function goVerifID() {
    return (
      <div>
        <Header/>
         <VerifID />
         <Footer/>
      </div>
    );
  }

  function goSearchUser() {
    return (
      <div>
        <Header/>
         <SearchUser />
         <Footer/>
      </div>
    );
  }

export default RouterOutlet;