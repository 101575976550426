import React, { Component } from 'react';
import './../../assets/css/multiple.css';
import './../../assets/css/index.css';
import './../../assets/css/modal.css';

class NavItem extends Component {
    render() {
        return (
            <li className="nav-item">
                <a type={this.props.type} className={this.props.className} href={this.props.href} onClick={this.props.action} >{this.props.text}</a>
            </li>
        );
    }
}

export default NavItem;