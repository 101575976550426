export const GET_PENDING_VAIDATION_ID_QUERY = `query {
    getListUsersIdCardInPendingAction(limit: 100) {
      status
      message
      users {
        Username
        Attributes {
          Name
          Value
        }
        UserCreateDate
        UserLastModifiedDate
        Enabled
        UserStatus
        MFAOptions {
          AttributeName
          DeliveryMedium
        }
      }
      PaginationToken
      errors{
        param
        msg
      }
    }
  }
`

export const VALIDATE_PENDING_VAIDATION_ID_QUERY = `mutation ($id_card_infos: IdCardInfosInput!) {
    validateUserIdCard(id_card_infos: $id_card_infos) {
      status
      message
      errors{
        param
        msg
      }
    }
  }
  `

export const REJECT_PENDING_VAIDATION_ID_QUERY = `mutation ($id_card_infos: IdCardInfosInput!) {
  rejectUserIdCard(id_card_infos: $id_card_infos) {
    status
    message
    errors{
      param
      msg
    }
  }
}
  `