import React, { Component, Fragment } from 'react';
import './../../assets/css/multiple.css';
import './../../assets/css/dashboard.css';
import './../../assets/css/modal.css';
import { withRouter } from 'react-router-dom';
import './../../assets/css/pages/vision-360.css';
import Nav from 'react-bootstrap/Nav';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveCalendar } from '@nivo/calendar';
import { ResponsiveChoropleth } from '@nivo/geo';
import COUNTRIES from './world_countries.json';
import DataTable from 'react-data-table-component';
import {Helmet} from 'react-helmet';

class Infosvisu extends Component {
    constructor(props) {

        super(props);
        this.state = {
            user: '',
        }
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.dataValue= [
            {
                "type": "test",
                "id":"vdfvfd",
                "departure_place":"Abidjan",
                "arrival_place":"Paris",
                "departure_date":"12/09/2020",
                "arrival_date":"12/09/2020",
                "status":"DELIVERED",
                "amount":"766",
            },
            {
                "type": "test",
                "id":"vdfvfd",
                "departure_place":"Abidjan",
                "arrival_place":"Paris",
                "departure_date":"12/09/2020",
                "arrival_date":"12/09/2020",
                "status":"DELIVERED",
                "amount":"766",
            },
            {
                "type": "test",
                "id":"vdfvfd",
                "departure_place":"Abidjan",
                "arrival_place":"Paris",
                "departure_date":"12/09/2020",
                "arrival_date":"12/09/2020",
                "status":"DELIVERED",
                "amount":"766",
            },
        ]
    }

    componentWillMount() {

    }

    handleUserChange(event) {
        this.setState({user: event.target.value});
    }

    handleSubmit(event){
        
    }

    render() {
        const columns = [
			{
				name: 'Type d\'opération',
				selector: 'type',
                sortable: true,
                button: true,
            },
            {
				name: 'ID ',
				selector: 'id',
                sortable: true,
                button: true,
            },
            {
				name: 'Lieu de départ',
				selector: 'departure_place',
                sortable: true,
                button: true,
            },
            {
				name: 'Lieu d\'arrivé',
				selector: 'arrival_place',
                sortable: true,
                button: true,
            },
            {
				name: 'Date de départ',
				selector: 'departure_date',
                sortable: true,
                button: true,
            },
            {
				name: 'Date d\'arrivée',
				selector: 'arrival_date',
                sortable: true,
                button: true,
            },
            {
				name: 'Status',
				selector: 'status',
                sortable: true,
                button: true,
            },
            {
				name: 'Montant',
				selector: 'amount',
                sortable: true,
                button: true,
			},
			// {
            //     name: 'Action',
            //     selector: 'action',
            //     sortable: true,
            //     button: true,
            //     cell: row => <div>
            //         {
            //                <div> 
            //                    <a href="" class="link color-blue" data-toggle="modal" data-target="#modal-write" onClick={(e) => {}}>Valider</a> / 
            //                    <a href="" class="link color-blue" data-toggle="modal" data-target="#modal-write" onClick={(e) => {}}>Refuser</a> 
            //                 </div>
            //         }
                    
            //     </div>
            // },
			
		];
        const dataPie = [
            {
                "id": "voyage éffectués",
                "label": "VE",
                "value": 300,
                "color": "hsl(252, 70%, 50%)"
            },
            {
                "id": "envois éffectués",
                "label": "EE",
                "value": 320,
                "color": "hsl(226, 70%, 50%)"
            },
            {
                "id": "participation crowfunding",
                "label": "PC",
                "value": 90,
                "color": "hsl(138, 70%, 50%)"
            },
            {
                "id": "commentaires laissés",
                "label": "CL",
                "value": 230,
                "color": "hsl(133, 70%, 50%)"
            },
            {
                "id": "commentaires recus",
                "label": "CR",
                "value": 434,
                "color": "hsl(309, 70%, 50%)"
            }
        ]

        const dataBar = [
            {
                "month": "Janvier",
                "voyage effectués": 54,
                "voyage effectuésColor": "hsl(27, 70%, 50%)",
                "envois effectués": 12,
                "envois effectuésColor": "hsl(218, 70%, 50%)",
                "participation crowfunding": 198,
                "participation crowfundingColor": "hsl(92, 70%, 50%)",
                "commentaires laissés": 62,
                "commentaires laissésColor": "hsl(25, 70%, 50%)",
                "commentaires recus": 138,
                "commentaires recusColor": "hsl(139, 70%, 50%)",
            },
            {
                "month": "Fevrier",
                "voyage effectués": 5,
                "voyage effectuésColor": "hsl(79, 70%, 50%)",
                "envois effectués": 71,
                "envois effectuésColor": "hsl(99, 70%, 50%)",
                "participation crowfunding": 30,
                "participation crowfundingColor": "hsl(352, 70%, 50%)",
                "commentaires laissés": 72,
                "commentaires laissésColor": "hsl(252, 70%, 50%)",
                "commentaires recus": 53,
                "commentaires recusColor": "hsl(16, 70%, 50%)"
            },
            {
                "month": "Mars",
                "voyage effectués": 194,
                "voyage effectuésColor": "hsl(172, 70%, 50%)",
                "envois effectués": 76,
                "envois effectuésColor": "hsl(148, 70%, 50%)",
                "participation crowfunding": 121,
                "participation crowfundingColor": "hsl(298, 70%, 50%)",
                "commentaires laissés": 102,
                "commentaires laissésColor": "hsl(346, 70%, 50%)",
                "commentaires recus": 163,
                "commentaires recusColor": "hsl(293, 70%, 50%)"
            },
            {
                "month": "Avril",
                "voyage effectués": 17,
                "voyage effectuésColor": "hsl(333, 70%, 50%)",
                "envois effectués": 60,
                "envois effectuésColor": "hsl(259, 70%, 50%)",
                "participation crowfunding": 136,
                "participation crowfundingColor": "hsl(215, 70%, 50%)",
                "commentaires laissés": 90,
                "commentaires laissésColor": "hsl(357, 70%, 50%)",
                "commentaires recus": 146,
                "commentaires recusColor": "hsl(282, 70%, 50%)"
            },
            {
                "month": "Mai",
                "voyage effectués": 92,
                "voyage effectuésColor": "hsl(102, 70%, 50%)",
                "envois effectués": 88,
                "envois effectuésColor": "hsl(271, 70%, 50%)",
                "participation crowfunding": 161,
                "participation crowfundingColor": "hsl(282, 70%, 50%)",
                "commentaires laissés": 24,
                "commentaires laissésColor": "hsl(146, 70%, 50%)",
                "commentaires recus": 62,
                "commentaires recusColor": "hsl(217, 70%, 50%)"
            },
            {
                "month": "Juin",
                "voyage effectués": 127,
                "voyage effectuésColor": "hsl(151, 70%, 50%)",
                "envois effectués": 5,
                "envois effectuésColor": "hsl(263, 70%, 50%)",
                "participation crowfunding": 184,
                "participation crowfundingColor": "hsl(278, 70%, 50%)",
                "commentaires laissés": 125,
                "commentaires laissésColor": "hsl(44, 70%, 50%)",
                "commentaires recus": 149,
                "commentaires recusColor": "hsl(221, 70%, 50%)"
            },
            {
                "month": "Juillet",
                "voyage effectués": 164,
                "voyage effectuésColor": "hsl(13, 70%, 50%)",
                "envois effectués": 127,
                "envois effectuésColor": "hsl(319, 70%, 50%)",
                "participation crowfunding": 148,
                "participation crowfundingColor": "hsl(148, 70%, 50%)",
                "commentaires laissés": 25,
                "commentaires laissésColor": "hsl(8, 70%, 50%)",
                "commentaires recus": 183,
                "commentaires recusColor": "hsl(132, 70%, 50%)"
            }
        ]

        const dataCalendar = [
            {
                "day": "2020-08-10",
                "value": 119
            },
            {
                "day": "2020-09-05",
                "value": 185
            },
            {
                "day": "2015-11-28",
                "value": 310
            },
            {
                "day": "2018-01-12",
                "value": 213
            },
            {
                "day": "2020-09-03",
                "value": 277
            },
            {
                "day": "2015-10-20",
                "value": 305
            },
            {
                "day": "2015-08-10",
                "value": 92
            },
            {
                "day": "2015-10-24",
                "value": 83
            },
            {
                "day": "2015-12-29",
                "value": 335
            },
            {
                "day": "2018-02-05",
                "value": 303
            },
            {
                "day": "2020-07-22",
                "value": 62
            },
            {
                "day": "2020-11-10",
                "value": 187
            },
            {
                "day": "2018-02-20",
                "value": 78
            },
            {
                "day": "2015-07-09",
                "value": 316
            },
            {
                "day": "2020-07-15",
                "value": 342
            },
            {
                "day": "2020-04-22",
                "value": 333
            },
            {
                "day": "2020-07-27",
                "value": 86
            },
            {
                "day": "2020-05-24",
                "value": 9
            },
            {
                "day": "2015-08-26",
                "value": 357
            },
            {
                "day": "2018-01-28",
                "value": 89
            },
            {
                "day": "2015-09-16",
                "value": 173
            },
            {
                "day": "2020-04-03",
                "value": 328
            },
            {
                "day": "2020-10-28",
                "value": 139
            },
            {
                "day": "2015-08-30",
                "value": 235
            },
            {
                "day": "2020-12-17",
                "value": 308
            },
            {
                "day": "2015-09-08",
                "value": 70
            },
            {
                "day": "2015-04-16",
                "value": 37
            },
            {
                "day": "2020-09-19",
                "value": 366
            },
            {
                "day": "2020-12-12",
                "value": 217
            },
            {
                "day": "2015-07-15",
                "value": 103
            },
            {
                "day": "2020-01-16",
                "value": 235
            },
            {
                "day": "2020-03-27",
                "value": 2
            },
            {
                "day": "2020-10-16",
                "value": 187
            },
            {
                "day": "2020-09-23",
                "value": 41
            },
            {
                "day": "2020-05-23",
                "value": 245
            },
            {
                "day": "2020-09-07",
                "value": 184
            },
            {
                "day": "2020-08-31",
                "value": 266
            }
        ]

        const dataMap = [

            {
                "id": "CIV",
                "value": 12
            },
            {
                "id": "CMR",
                "value": 57
            },
            {
                "id": "COG",
                "value": 90
            },
            {
                "id": "COL",
                "value": 45
            },
            {
                "id": "CRI",
                "value": 13
            },
            {
                "id": "CUB",
                "value": 2
            },
            {
                "id": "-99",
                "value": 9
            },
            {
                "id": "CYP",
                "value": 23
            }
        ]
        return (
            <Fragment>
            <Helmet>
                <title>Infos Vision 360 | Monkolis</title>
            </Helmet>
            <div className="container-fluid">
                <div className="container">
                        <form className="well" onSubmit={this.handleSubmit}>
                            <div className="col-lg-6 col-12 m-auto">
                                <div className="input-group shadow-sm p-3">
                                    <input type="search" className="form-control rounded" placeholder="Que recherchez-vous ?" aria-label="Search" aria-describedby="search-addon" value={this.state.user} onChange={this.handleUserChange} />
                                    <button type="button" class="btn btn-primary">Trouver un trajet</button>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body card-vh w-100" style={{height:300}}>
                                    <ResponsivePie
                                        data={dataPie}
                                        margin={{ top: 4, right: 40, bottom: 60, left: 40 }}
                                        innerRadius={0.5}
                                        padAngle={0.7}
                                        cornerRadius={3}
                                        colors={{ scheme: 'nivo' }}
                                        borderWidth={1}
                                        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                        radialLabelsSkipAngle={10}
                                        radialLabelsTextXOffset={6}
                                        radialLabelsTextColor="#333333"
                                        radialLabelsLinkOffset={0}
                                        radialLabelsLinkDiagonalLength={16}
                                        radialLabelsLinkHorizontalLength={24}
                                        radialLabelsLinkStrokeWidth={1}
                                        radialLabelsLinkColor={{ from: 'color' }}
                                        slicesLabelsSkipAngle={10}
                                        slicesLabelsTextColor="#333333"
                                        animate={true}
                                        motionStiffness={90}
                                        motionDamping={15}
                                        // defs={[
                                        //     {
                                        //         id: 'dots',
                                        //         type: 'patternDots',
                                        //         background: 'inherit',
                                        //         color: 'rgba(255, 255, 255, 0.3)',
                                        //         size: 4,
                                        //         padding: 5,
                                        //         stagger: true
                                        //     },
                                        //     {
                                        //         id: 'lines',
                                        //         type: 'patternLines',
                                        //         background: 'inherit',
                                        //         color: 'rgba(255, 255, 255, 0.3)',
                                        //         rotation: -45,
                                        //         lineWidth: 6,
                                        //         spacing: 100
                                        //     }
                                        // ]}
                                        // fill={[
                                        //     {
                                        //         match: {
                                        //             id: 'ruby'
                                        //         },
                                        //         id: 'dots'
                                        //     },
                                        //     {
                                        //         match: {
                                        //             id: 'travel_made'
                                        //         },
                                        //         id: 'dots'
                                        //     },
                                        //     {
                                        //         match: {
                                        //             id: 'go'
                                        //         },
                                        //         id: 'dots'
                                        //     },
                                        //     {
                                        //         match: {
                                        //             id: 'python'
                                        //         },
                                        //         id: 'dots'
                                        //     },
                                        //     {
                                        //         match: {
                                        //             id: 'scala'
                                        //         },
                                        //         id: 'lines'
                                        //     },
                                        //     {
                                        //         match: {
                                        //             id: 'lisp'
                                        //         },
                                        //         id: 'lines'
                                        //     },
                                        //     {
                                        //         match: {
                                        //             id: 'elixir'
                                        //         },
                                        //         id: 'lines'
                                        //     },
                                        //     {
                                        //         match: {
                                        //             id: 'javascript'
                                        //         },
                                        //         id: 'lines'
                                        //     }
                                        // ]}
                                        legends={[
                                            {
                                                anchor: 'bottom',
                                                direction: 'row',
                                                translateY: 56,
                                                itemWidth: 100,
                                                itemHeight: 18,
                                                itemTextColor: '#999',
                                                symbolSize: 18,
                                                symbolShape: 'circle',
                                                padding: 5,
                                                effects: [
                                                    {
                                                        on: 'hover',
                                                        style: {
                                                            itemTextColor: '#000'
                                                        }
                                                    }
                                                ]
                                            }
                                        ]}
                                    />

                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body card-vh w-100" style={{height:400}}>
                                    <ResponsiveBar
                                        data={dataBar}
                                        keys={['voyage effectués', 'envois effectués', 'participation crowfunding', 'commentaires laissés', 'commentaires recus']}
                                        indexBy="month"
                                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                        padding={0.3}
                                        colors={{ scheme: 'nivo' }}
                                        defs={[
                                            {
                                                id: 'dots',
                                                type: 'patternDots',
                                                background: 'inherit',
                                                color: '#38bcb2',
                                                size: 4,
                                                padding: 1,
                                                stagger: true
                                            },
                                            {
                                                id: 'lines',
                                                type: 'patternLines',
                                                background: 'inherit',
                                                color: '#eed312',
                                                rotation: -45,
                                                lineWidth: 6,
                                                spacing: 10
                                            }
                                        ]}
                                        fill={[
                                            {
                                                match: {
                                                    id: 'commentaires recus'
                                                },
                                                id: 'dots'
                                            },
                                            {
                                                match: {
                                                    id: 'participation crowfunding'
                                                },
                                                id: 'lines'
                                            }
                                        ]}
                                        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'month',
                                            legendPosition: 'middle',
                                            legendOffset: 32
                                        }}
                                        axisLeft={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'activités',
                                            legendPosition: 'middle',
                                            legendOffset: -40
                                        }}
                                        labelSkipWidth={12}
                                        labelSkipHeight={12}
                                        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                        legends={[
                                            {
                                                dataFrom: 'keys',
                                                anchor: 'bottom-right',
                                                direction: 'column',
                                                justify: false,
                                                translateX: 120,
                                                translateY: 0,
                                                itemsSpacing: 2,
                                                itemWidth: 100,
                                                itemHeight: 20,
                                                itemDirection: 'left-to-right',
                                                itemOpacity: 0.85,
                                                symbolSize: 20,
                                                effects: [
                                                    {
                                                        on: 'hover',
                                                        style: {
                                                            itemOpacity: 1
                                                        }
                                                    }
                                                ]
                                            }
                                        ]}
                                        animate={true}
                                        motionStiffness={90}
                                        motionDamping={15}
                                    />
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body card-vh" style={{height:400}}>
                                            <ResponsiveCalendar
                                                data={dataCalendar}
                                                from="2020-01-01"
                                                to="2020-01-12"
                                                emptyColor="#eeeeee"
                                                colors={['#61cdbb', '#97e3d5', '#e8c1a0', '#f47560']}
                                                margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                                                yearSpacing={40}
                                                monthBorderColor="#ffffff"
                                                dayBorderWidth={2}
                                                dayBorderColor="#ffffff"
                                                legends={[
                                                    {
                                                        anchor: 'bottom-right',
                                                        direction: 'row',
                                                        translateY: 36,
                                                        itemCount: 4,
                                                        itemWidth: 42,
                                                        itemHeight: 36,
                                                        itemsSpacing: 14,
                                                        itemDirection: 'right-to-left'
                                                    }
                                                ]}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body card-vh" style={{height:400}}>
                                                <h4 className="card-title mt-1 mb-75 pt-25"> Pays & Région</h4>
                                                <ResponsiveChoropleth
                                                    data={dataMap}
                                                    features={COUNTRIES['features']}
                                                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                                                    colors="nivo"
                                                    domain={[0, 100]}
                                                    unknownColor="#666666"
                                                    label="properties.name"
                                                    valueFormat=".2s"
                                                    projectionTranslation={[0.5, 0.5]}
                                                    projectionRotation={[0, 0, 0]}
                                                    enableGraticule={true}
                                                    graticuleLineColor="#dddddd"
                                                    borderWidth={0.5}
                                                    borderColor="#152538"
                                                    legends={[
                                                        {
                                                            anchor: 'bottom-left',
                                                            direction: 'column',
                                                            justify: true,
                                                            translateX: 20,
                                                            translateY: -100,
                                                            itemsSpacing: 0,
                                                            itemWidth: 94,
                                                            itemHeight: 18,
                                                            itemDirection: 'left-to-right',
                                                            itemTextColor: '#444444',
                                                            itemOpacity: 0.85,
                                                            symbolSize: 18,
                                                            effects: [
                                                                {
                                                                    on: 'hover',
                                                                    style: {
                                                                        itemTextColor: '#000000',
                                                                        itemOpacity: 1
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                            
                                </div>
                            </div>  
                            <div className="container">
                                <div className="row">
                                    <div className="card">
                                        <div className="card-body card-vh">
                                            <DataTable
                                                title="Liste des activités"
                                                columns={columns}
                                                data={this.dataValue}
                                                pagination
                                                selectableRowsComponent
                                            />
                                        </div>
                                    </div>
                            
                                </div>
						    </div>
                        </form>

                </div>
            </div>
            </Fragment>
        );
    }
}
export default withRouter(Infosvisu);