export const GET_VALIDATION_PENDING_TICKET_QUERY= `query {
    getListTravelTicketInPendingAction(limit: 100) {
      status
      message
      travel_proposals {
        id_travel
        id_user_proposal
        registration_date
        departure_date
        arrival_date
        departure_city
        arrival_city
        has_check_ticket
        max_weight_available
        min_weight_booking
        proposal_amount_by_kg
        measure_unit
        volume
        booking_deadline
        service_charge_amount
        is_automatic_approval
        transport_company
        transportation_type
        transportation_condition
        others_infos
        take_off_place
        recovery_place
        status
        ticket_url
        ticket_details {
          ticket_number
          ticket_rejected_reason
          last_action_date
          backoffice_user_sub
        }
        weigth_available_info {
          weigth_available
          last_update_date
        }
        traveler_cancel_info {
          traveler_cancel_date
          send_mails_to_requesters
          comment
        }
        system_traveler_reimbursment_info {
          date
          reimbursment_date
          reimbursment_amount
          status
        }
        user_proposal_attributes {
          email_verified
          name
          family_name
          given_name
          picture
          birthdate
          address{
            street_address
            locality
            region
            postal_code
            country
          }
          email
          username
          gender
          city_of_residence
          additional_infos
          description
          last_update_date
          account_type
       }
       travel_type_info {
          type_info
          specific_info
          travel_id_depart
          travel_id_return
       }
       make_report_on_travel_notification {
          report_id
          report_date
          report_object
          report_content
          type_notification
          status
          expeditor_id_user
       }
      }
      errors{
        param
        msg
      }
    }
  }
`

export const VALIDATE_TICKET_QUERY = `mutation ($travel_details: TravelDetailsInput!) {
  validateTravelTicket(travel_details: $travel_details) {
    status
    message
    errors{
      param
      msg
    }
  }
}
`
export const REJECT_TICKET_QUERY = `mutation ($travel_details: TravelDetailsInput!) {
  rejectTravelTicket(travel_details: $travel_details) {
    status
    message
    errors{
      param
      msg
    }
  }
}
`