import React, { Component,Fragment } from 'react';
import './../../assets/css/dashboard.css';
import './../../assets/css/modal.css';
import './../../assets/css/main.css';
import './../../assets/css/modal.css';
import './../../assets/css/card.css'; 
import './../../assets/css/components/app-content.css';
import { withRouter } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import DataTable from 'react-data-table-component';
import { GET_PENDING_VAIDATION_ID_QUERY, VALIDATE_PENDING_VAIDATION_ID_QUERY, REJECT_PENDING_VAIDATION_ID_QUERY } from '../../query/queryUserID';
import { BACK_OFFICE_URL } from '../../Url';
import axios from 'axios';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import Modal from 'react-bootstrap/Modal';

class VerifID extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataValue: [],
            id_card_type: "Carte d'identité",
            id_card_number: "",
            id_card_expiration_date: "",
            id_card_country: "",
            showValidationTicketModal: false,
            showRejectModal: false,
            motif: 'Pièce d\'identité non lisible',
            tmp_user_sub: '',
            tmp_user_name: '',
        }
        
        this.data = [];
        this.handleShowValidationModal = this.handleShowValidationModal.bind(this);
        this.handleValidationTicketClick = this.handleValidationTicketClick.bind(this);
        this.handleCloseValidationTicketModal = this.handleCloseValidationTicketModal.bind(this);
        this.handleshowRejectModal = this.handleshowRejectModal.bind(this);
        this.handleRejetReservationClick = this.handleRejetReservationClick.bind(this);
        this.handleIDCardNumberChange = this.handleIDCardNumberChange.bind(this);
        this.handleChangeMotif = this.handleChangeMotif.bind(this);
        this.handleCloseRejectModal = this.handleCloseRejectModal.bind(this);
        this.handleIDCardTypeChange = this.handleIDCardTypeChange.bind(this);
        this.handleIDCardExpDateChange = this.handleIDCardExpDateChange.bind(this);
        this.handleIDCardCountryChange = this.handleIDCardCountryChange.bind(this);
    }

    handleChangeMotif(event) {
        this.setState({ motif: event.target.value })
    }

    handleIDCardTypeChange(event) {
        this.setState({ id_card_type: event.target.value })
    }

handleIDCardExpDateChange(event) {
        this.setState({ id_card_expiration_date: event.target.value })
    }

handleIDCardCountryChange(event) {
    this.setState({ id_card_country: event.target.value })
}

    handleIDCardNumberChange(event) {
        this.setState({ id_card_number: event.target.value })
    }

    handleRejetReservationClick(event) {
        event.preventDefault();
        var VAR = JSON.stringify({
            "id_card_infos" : {
                "username": this.state.tmp_user_name,
                "user_sub": this.state.tmp_user_sub,
                "id_card_rejected_reason": this.state.motif
           }
        });
        console.log(VAR)
        this.rejectReservationRequest(REJECT_PENDING_VAIDATION_ID_QUERY, VAR)
    }

    rejectReservationRequest = async (query, variables) => {
        try {
            const response = await axios.post(BACK_OFFICE_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );
            // Set the data to the state
             console.log(response.data.data)   
            if (response.data.data.rejectUserIdCard.status === 200) {
                alert('la pièce d\'identité a bien été rejeté !')
                window.location.reload(false);
            } else {
                alert('Echec lors du décodage')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('error in sending')
            console.log(error)
        }
    }

    handleCloseValidationTicketModal(event, id_proposal) {
        this.setState(
            {
                showValidationTicketModal: false
            }
        )
    }

    handleValidationTicketClick(event) {
        event.preventDefault();
        var VAR = JSON.stringify({
           "id_card_infos" : {
                "username": this.state.tmp_user_name,
                "user_sub": this.state.tmp_user_sub,
                "id_card_type": this.state.id_card_type,
                "id_card_number": this.state.id_card_number,
                "id_card_expiration_date": this.state.id_card_expiration_date,
                "id_card_country": this.state.id_card_country
            }
        });
        console.log(VAR)
        this.validateTicket(VALIDATE_PENDING_VAIDATION_ID_QUERY, VAR)
    }

    validateTicket = async (query, variables) => {
        try {
            const response = await axios.post(BACK_OFFICE_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );
            // Set the data to the state

            if (response.data.data.validateUserIdCard.status === 200) {
                alert('la pièce d\'identité a été validé!')
                window.location.reload(false);
            } else {
                alert('Echec lors du décodage')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('error in sending')
            console.log(error)
        }
    }

    handleShowValidationModal(event, user_sub, username) {
        event.preventDefault();
        this.setState({
            showValidationTicketModal: true,
            tmp_user_sub: user_sub,
            tmp_user_name: username
        })
    }

    handleCloseRejectModal() {
        this.setState(
            { showRejectModal: !this.state.showRejectModal }
        )
    }

    handleshowRejectModal(event, user_sub, username) {
        event.preventDefault();
        this.setState({
            showRejectModal: !this.state.showRejectModal,
            tmp_user_sub: user_sub,
            tmp_user_name: username
        }
        )
    }

    getTravelerList = async (url, idToken) => {
        try {
            const response = await axios.post(url,
                { "query": GET_PENDING_VAIDATION_ID_QUERY },
                {
                    'headers':
                    {
                        'Authorization': idToken
                    }
                }
            );
            this.data = response.data.data.getListUsersIdCardInPendingAction.users
            const tmp = []
            this.data.forEach(element => {
                tmp.push({
                    name:  element.Attributes.find(elt => elt.Name === 'family_name') ? element.Attributes.find(elt => elt.Name === 'family_name').Value : '',
                    given_name:  element.Attributes.find(elt => elt.Name === 'given_name') ? element.Attributes.find(elt => elt.Name === 'given_name').Value : '',
                    user_sub: element.Attributes.find(elt => elt.Name === 'sub') ? element.Attributes.find(elt => elt.Name === 'sub').Value : '',
                    url: element.Attributes.find(elt => elt.Name === 'custom:id_card_url') ? element.Attributes.find(elt => elt.Name === 'custom:id_card_url').Value : '',
                    username: element.Username,
                    phone: element.Attributes.find(elt => elt.Name === 'phone_number') ? element.Attributes.find(elt => elt.Name === 'phone_number').Value : '',
                    email: element.Attributes.find(elt => elt.Name === 'email') ? element.Attributes.find(elt => elt.Name === 'email').Value : '',
                });
            });
            this.setState({ dataValue: tmp });
        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            console.log(error)
        }
    }

    componentWillMount() {
        this.idToken = getCookieConsentValue('idToken');
        this.getTravelerList(BACK_OFFICE_URL, this.idToken);
    }

    render() {
        const columns = [
			{
				name: 'Pièce d\'identité',
				selector: 'url',
                sortable: true,
                button: true,
                cell: row => <div>
                    {
                           <div> 
                               <a href={row.url} target="popup" class="link color-blue"> Voir la pièce</a>
                            </div>
                    }
                    
                </div>
            },
            {
				name: 'Nom',
				selector: 'name',
                sortable: true,
                button: false,
            },
            {
				name: 'Prenoms',
				selector: 'given_name',
                sortable: true,
                button: false,
            },
            {
				name: 'Téléphone',
				selector: 'phone',
                sortable: true,
                button: false,
            },
            {
				name: 'Email',
				selector: 'email',
                sortable: true,
                button: false,
			},
			{
                name: 'Action',
                selector: 'action',
                sortable: true,
                button: true,
                cell: row => <div>
                    {
                        <div className="btn-group btn-group-sm d-flex"> 
                            <a href="" className="btn btn-primary btn-sm w-100" data-toggle="modal" data-target="#modal-write" onClick={(e) => { this.handleShowValidationModal(e, row.user_sub, row.username) }}><span className="fa fa-1x fa-check-square"></span></a> 
                            <a href="" className="btn btn-danger btn-sm w-100" data-toggle="modal" data-target="#modal-write" onClick={(e) => {this.handleshowRejectModal(e, row.user_sub, row.username)}}><span className="fa fa-1x fa-trash"></span></a> 
                        </div>
                    }
                    
                </div>
            },
			
		];

        return (
                <Fragment>
                    <div className="app-layout">
                        <div className="app-content content">
                            <div className="content-overlay"></div>
                            <div className="content-wrapper">
                                <div className="content-body">

                                    <Modal show={this.state.showValidationTicketModal} onHide={this.handleCloseValidationTicketModal} centered>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Valider la pièce d'identité</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="form-group">
                                                <span>Veuillez saisir l'id de la piece d'identité</span>
                                                <input type="text" className="form-control" id="travelFirm" placeholder="No carte" value={this.state.id_card_number} onChange={this.handleIDCardNumberChange} required="true" />
                                                <span>Veuillez saisir le type de la piece d'identité</span>
                                                <select className="form-control" value={this.state.id_card_type} onChange={this.handleIDCardTypeChange}>
                                                        <option selected >Carte d'identité</option>
                                                        <option>Passeport</option>
                                                        <option>Titre de séjour</option>
                                                        <option>Permis de conduire</option>
                                                        <option>Autre</option>
                                                    </select>
                                                <span>Veuillez saisir la date d'expiration de la piece d'identité</span>    
                                                <input type="date" className="form-control" id="travelFirm" placeholder="Date d'expiration" value={this.state.id_card_expiration_date} onChange={this.handleIDCardExpDateChange} required="true" />
                                                <span>Veuillez saisir le pays de délivrance de la piece d'identité</span>
                                                <input type="text" className="form-control" id="travelFirm" placeholder="Pays de délivrance" value={this.state.id_card_country} onChange={this.handleIDCardCountryChange} required="true" />
                                                
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button className="btn btn-outline-primary" onClick={this.handleCloseValidationTicketModal}>
                                                Annuler
                                            </button>
                                            <button className="btn btn-primary" onClick={(e) => { this.handleValidationTicketClick(e) }}>
                                                Valider
                                            </button>
                                        </Modal.Footer>
                                    </Modal>

                                    <Modal show={this.state.showRejectModal} onHide={this.handleCloseRejectModal} centered>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Refuser la pièce d'identité</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="modal-body">
                                                <span className="txt-bold">Pourquoi refusez-vous la pièce d'identité ?</span>
                                                <br />
                                                <br />

                                                <div className="form-group">
                                                    <label for="exampleFormControlTextarea1">Motif</label>
                                                    <select className="form-control" value={this.state.motif} onChange={this.handleChangeMotif}>
                                                        <option selected >Pièce d'identité non lisible</option>
                                                        <option >Pièce d'identité non conforme</option>
                                                        <option >Pièce d'identité expirée</option>
                                                        <option >Pièce d'identité falsifiée</option>
                                                        <option >Prière de nous transmettre le recto et le verso de votre pièce</option>
                                                        <option > Nom de la pièce d'identité différent du nom sur le compte</option>
                                                    </select>

                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button className="btn btn-outline-primary" onClick={this.handleCloseRejectModal}>
                                                Annuler
                                            </button>
                                            <button className="btn btn-primary" onClick={this.handleRejetReservationClick}>
                                                Valider
                                            </button>
                                        </Modal.Footer>
                                    </Modal>
                                    <div className="container-fluid">
                                        <div className="row match-height">

                                            <div className="card">
                                                <div className="card-body">
                                                    <DataTable
                                                        title="Liste des pièces d'identité à valider"
                                                        columns={columns}
                                                        data={this.state.dataValue}
                                                        pagination
                                                        selectableRowsComponent
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div> 
                            </div>

                        </div> 
                    </div> 
                </Fragment>
                );
               

    }
}
export default withRouter(VerifID);