import React, { Component, Fragment } from 'react';
import './../../assets/css/multiple.css';
import './../../assets/css/dashboard.css';
import './../../assets/css/modal.css';
import { withRouter } from 'react-router-dom';

import Moment from "moment";
import DataTable from 'react-data-table-component';
class Reporting extends Component {
	constructor(props) {

		super(props);
		this.state = {

		}
		this.dataValue = [
			{
				date: Moment("09-08-2020").format('D-MM-YYYY'),
				id_voyageur: "fjsn",
				traveler_name: "test",
				amount: "40",
				balance:"200"
			},
			{
				date: Moment("09-08-2020").format('D-MM-YYYY'),
				id_voyageur: "fjsn",
				traveler_name: "test",
				amount: "40",
				balance:"200"
			},
			{
				date: Moment("09-08-2020").format('D-MM-YYYY'),
				id_voyageur: "fjsn",
				traveler_name: "test",
				amount: "40",
				balance:"200"
			},
			{
				date: Moment("09-08-2020").format('D-MM-YYYY'),
				id_voyageur: "fjsn",
				traveler_name: "test",
				amount: "40",
				balance:"200"
			},
			{
				date: Moment("09-08-2020").format('D-MM-YYYY'),
				id_voyageur: "fjsn",
				traveler_name: "test",
				amount: "40",
				balance:"200"
			},
			{
				date: Moment("09-08-2020").format('D-MM-YYYY'),
				id_voyageur: "fjsn",
				traveler_name: "test",
				amount: "40",
				balance:"200"
			},
			{
				date: Moment("09-08-2020").format('D-MM-YYYY'),
				id_voyageur: "fjsn",
				traveler_name: "test",
				amount: "40",
				balance:"200"
			}
		]
	}

	componentWillMount() {

	}



	render() {
		const columns = [
			{
				name: 'Date de paiement',
				selector: 'date',
				sortable: true,
			},
			{
				name: 'Id du voyageur',
				selector: 'id_voyageur',
				sortable: true,
			},
			{
				name: 'Nom du voyageur',
				selector: 'traveler_name',
				sortable: true,
			},
			{
				name: 'Montant',
				selector: 'amount',
				sortable: true,
			},
			{
				name: 'Solde total',
				selector: 'balance',
				sortable: true,
			}
		];
		return (
			<Fragment>
				<div className="app-layout mar-bot30">
					<div className="app-content content">
						<div className="content-overlay"></div>
						<div className="content-wrapper">
							<div className="content-body">
								<div className="container-fluid">
									<div className="row match-height">
										<DataTable
											title="Liste des contributions"
											columns={columns}
											data={this.dataValue}
											pagination
											selectableRowsComponent
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}
export default withRouter(Reporting);