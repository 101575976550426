import React, { Component, Fragment } from 'react';
import './../../assets/css/multiple.css';
import './../../assets/css/dashboard.css';
import './../../assets/css/modal.css';
import './../../assets/css/main.css';
import './../../assets/css/annex.css';
import './../../assets/css/crow.css';
import './../../assets/css/multiple.css';
import './../../assets/css/modal.css';
import './../../assets/fa/fa.css';
import './../../assets/css/card.css'; 
import './../../assets/css/responsive.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { withRouter } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import DataTable from 'react-data-table-component';
import { GET_VALIDATION_PENDING_TICKET_QUERY, VALIDATE_TICKET_QUERY, REJECT_TICKET_QUERY } from '../../query/querytravelTicket';
import { BACK_OFFICE_URL } from '../../Url';
import axios from 'axios';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import {Helmet} from 'react-helmet';

class VerifTicket extends Component {
    constructor(props) {

        super(props);
        this.state = {
            dataValue: [],
            id_ticket: '',
            showValidationTicketModal: false,
            showRejectModal: false,
            motif: 'Billet non lisible',
            tmp_id_travel: '',
        }
        this.data = [];
        this.handleShowValidationModal = this.handleShowValidationModal.bind(this);
        this.handleValidationTicketClick = this.handleValidationTicketClick.bind(this);
        this.handleCloseValidationTicketModal = this.handleCloseValidationTicketModal.bind(this);
        this.handleshowRejectModal = this.handleshowRejectModal.bind(this);
        this.handleRejetReservationClick = this.handleRejetReservationClick.bind(this);
        this.handleIDTicketChange = this.handleIDTicketChange.bind(this);
        this.handleChangeMotif = this.handleChangeMotif.bind(this);
        this.handleCloseRejectModal = this.handleCloseRejectModal.bind(this);
    }

    handleChangeMotif(event) {
        this.setState({ motif: event.target.value })
    }

    handleIDTicketChange(event) {
        this.setState({ id_ticket: event.target.value })
    }

    handleRejetReservationClick(event) {
        event.preventDefault();
        var VAR = JSON.stringify({
                "travel_details" : {
                     "id_travel": this.state.tmp_id_travel,
                     "ticket_rejected_reason": this.state.motif
                }
        });
        this.rejectReservationRequest(REJECT_TICKET_QUERY, VAR)
    }

    rejectReservationRequest = async (query, variables) => {
        try {
            const response = await axios.post(BACK_OFFICE_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );
            // Set the data to the state 
            if (response.data.data.rejectTravelTicket.status === 200) {
                alert('Le billet a bien été rejeté !')
                window.location.reload(false);
            } else {
                alert('Echec lors du décodage')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('error in sending')
        }
    }

    handleCloseValidationTicketModal(event, id_proposal) {
        this.setState(
            {
                showValidationTicketModal: false
            }
        )
    }

    handleValidationTicketClick(event) {
        event.preventDefault();
        var VAR = JSON.stringify({
            "travel_details" : {
                "id_travel": this.state.tmp_id_travel,
                "ticket_number": this.state.id_ticket
           }
        });
        this.validateTicket(VALIDATE_TICKET_QUERY, VAR)
    }

    validateTicket = async (query, variables) => {
        try {
            const response = await axios.post(BACK_OFFICE_URL,
                { query, variables },
                {
                    'headers':
                    {
                        'Authorization': this.idToken
                    }
                }
            );
            // Set the data to the state

            if (response.data.data.validateTravelTicket.status === 200) {
                alert('Le Billet a été validé!')
                window.location.reload(false);
            } else {
                alert('Echec lors du décodage')
            }

        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
            alert('error in sending')
        }
    }

    handleShowValidationModal(event, id_travel) {
        event.preventDefault();
        this.setState({
            showValidationTicketModal: true,
            tmp_id_travel: id_travel
        })
    }

    handleCloseRejectModal() {
        this.setState(
            { showRejectModal: !this.state.showRejectModal }
        )
    }

    handleshowRejectModal(event, id_travel) {
        event.preventDefault();
        this.setState({
            showRejectModal: !this.state.showRejectModal,
            tmp_id_travel: id_travel
        }
        )
    }

    getTravelerList = async (url, idToken) => {
        try {
            const response = await axios.post(url,
                { "query": GET_VALIDATION_PENDING_TICKET_QUERY },
                {
                    'headers':
                    {
                        'Authorization': idToken
                    }
                }
            );
            this.data = response.data.data.getListTravelTicketInPendingAction.travel_proposals
            const tmp = []
            this.data.forEach(element => {
                tmp.push({
                    id_travel: element.id_travel,
                    id_user_proposal: element.id_user_proposal,
                    url: element.ticket_url,
                    name: element.user_proposal_attributes.name,
                    departure_city: element.departure_city,
                    departure_date: moment(element.departure_date).format('DD-MM-yyyy'),
                    arrival_city: element.arrival_city
                });
            });
            this.setState({ dataValue: tmp });
        } catch (error) {
            // If there's an error, set the error to the state
            this.setState(() => ({ error }))
        }
    }

    componentWillMount() {
        this.idToken = getCookieConsentValue('idToken');
        this.getTravelerList(BACK_OFFICE_URL, this.idToken);
    }

    render() {
        const columns = [
            {
                name: 'Titre de transport',
                selector: 'url',
                sortable: true,
                button: true,
                cell: row => <div>
                    {
                        <div>
                            <a href={row.url} target="_blank" class="link color-blue" > Voir le billet </a>
                        </div>
                    }
                </div>

            },
            {
                name: 'Nom du voyageur',
                selector: 'name',
                sortable: true,
                button: true,
                cell: row => <div>
                    {
                        <div>
                            <a> {row.name} </a>
                        </div>
                    }
                </div>

            },
            {
                name: 'Ville de départ',
                selector: 'departure_city',
                sortable: true,
                button: true,
                cell: row => <div>
                    {
                        <div>
                            <a> {row.departure_city} </a>
                        </div>
                    }
                </div>

            },
            {
                name: 'Ville d\'arrivée',
                selector: 'arrival_city',
                sortable: true,
                button: true,
                cell: row => <div>
                    {
                        <div>
                            <a> {row.arrival_city} </a>
                        </div>
                    }
                </div>

            },
            {
                name: 'Date du voyage',
                selector: 'departure_date',
                sortable: true,
                button: true,
                cell: row => <div>
                    {
                        <div>
                            <a> {row.departure_date} </a>
                        </div>
                    }
                </div>

            },
            {
                name: 'Action',
                selector: 'action',
                sortable: true,
                button: true,
                cell: row => <div>
                    {
                        <div className="btn-group btn-group-sm d-flex">
                            <a href="" className="btn btn-primary btn-sm w-100" data-toggle="modal" data-target="#modal-write" onClick={(e) => { this.handleShowValidationModal(e, row.id_travel) }}><span className="fa fa-1x fa-check-square"></span></a> 
                               <a href="" className="btn btn-danger btn-sm w-100" data-toggle="modal" data-target="#modal-write" onClick={(e) => { this.handleshowRejectModal(e, row.id_travel) }}><span className="fa fa-1x fa-trash"></span></a>
                        </div>
                    }

                </div>
            },

        ];
        return (
            <Fragment>
                <Helmet>
					<title>Vérification des billets d'avionst | Monkolis</title>
				</Helmet>
                <div className="app-layout">
                    <div className="app-content content">
                        <div className="content-overlay"></div>
                        <div className="content-wrapper">
                            <div className="content-body">

                                <Modal show={this.state.showValidationTicketModal} onHide={this.handleCloseValidationTicketModal} centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Valider le billet</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="form-group">
                                            <span>Veuillez saisir l'id du ticket</span>
                                            <input type="text" className="form-control" id="travelFirm" placeholder="ID Ticket" value={this.state.id_ticket} onChange={this.handleIDTicketChange} required="true" />
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button className="btn btn-outline-primary" onClick={this.handleCloseValidationTicketModal}>
                                            Annuler
                                        </button>
                                        <button className="btn btn-primary" onClick={(e) => { this.handleValidationTicketClick(e) }}>
                                            Valider
                                        </button>
                                    </Modal.Footer>
                                </Modal>

                                <Modal show={this.state.showRejectModal} onHide={this.handleCloseRejectModal} centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Refuser le billet</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="modal-body">
                                            <span className="txt-bold">Pourquoi refusez-vous le billet.</span>
                                            <br />
                                            <br />

                                            <div className="form-group">
                                                <label for="exampleFormControlTextarea1">Motif</label>
                                                <select className="form-control" value={this.state.motif} onChange={this.handleChangeMotif}>
                                                    <option>Billet non lisible</option>
                                                    <option>Billet non conforme</option>
                                                    <option>Billet expiré</option>
                                                    <option>Billet falsifié</option>
                                                </select>

                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button className="btn btn-outline-primary" onClick={this.handleCloseRejectModal}>
                                            Annuler
                                        </button>
                                        <button className="btn btn-primary" onClick={this.handleRejetReservationClick}>
                                            Valider
                                        </button>
                                    </Modal.Footer>
                                </Modal>
                                <div className="container-fluid">
                                    <div className="row match-height">

                                        <div className="card">
                                            <div className="card-body">
                                                <DataTable
                                                    title="Liste des billets à valider"
                                                    columns={columns}
                                                    data={this.state.dataValue}
                                                    pagination
                                                    selectableRowsComponent
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default withRouter(VerifTicket);