import React, { Component, Fragment } from 'react';
import logo from './../../assets/img/logo/MonKolisa.png';
import NavItem from './../navitem/navitem';

import Nav from 'react-bootstrap/Nav';
import FeatherIcon from 'feather-icons-react';
import './../../assets/css/index.css';
import './../../assets/css/modal.css';
import './../../assets/css/components/header.css';
import './../../assets/css/components/avatar.css';
// import './../../assets/css/plugins/bootstrap-extended.min.css';

import PHOTO from '../../assets/img/user/user0.png'
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';

import { withRouter } from 'react-router-dom';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

        this.handleDeconnexionClick = this.handleDeconnexionClick.bind(this);
    }

    componentDidMount() {
       
    }


    componentWillMount() {

    }

    
    handleDeconnexionClick(event) {
        event.preventDefault();
        localStorage.clear();
        sessionStorage.clear();
        Cookies.remove('idToken', { path: '/' });
        this.props.history.push({
            pathname: '/'
        });

    }

   
    render() {
            return (
                <Fragment>

                <div className="header-layout header-menu navbar-floating" data-open="hover" data-menu="horizontal-menu" data-col="">
                    <div className="header-navbar navbar-expand-lg navbar navbar-fixed align-items-center navbar-shadow navbar-brand-center" data-nav="brand-center">
                        <div className="navbar-header d-xl-block d-none">
                            <ul className="nav navbar-nav">
                                <li class="nav-item">
                                    <a class="navbar-brand"><img src={logo} alt="MonKolis"/></a>
                                </li>
                            </ul>

                        </div>
                        {/* End Navbar-header */}

                        <div className="navbar-container d-flex content">
                            <div className="bookmark-wrapper d-flex align-items-center">
                                <ul className="nav navbar-nav d-xl-none">
                                    <li className="nav-item"><a className="nav-link menu-toggle" href="javascript:void(0);"><FeatherIcon icon="menu" /></a></li>
                                </ul>
                                <ul className="nav navbar-nav bookmark-icons">
                                    <li className="nav-item d-none d-lg-block"><a className="nav-link" href="/app-email" data-toggle="tooltip" data-placement="top" title="Email"><FeatherIcon icon="mail" /></a></li>
                                    <li className="nav-item d-none d-lg-block"><a className="nav-link" href="/app-chat" data-toggle="tooltip" data-placement="top" title="Chat"><FeatherIcon icon="message-square" /></a></li>
                                    <li className="nav-item d-none d-lg-block"><a className="nav-link" href="/app-calendar" data-toggle="tooltip" data-placement="top" title="Calendar"><FeatherIcon icon="calendar" /></a></li>
                                </ul>
                            </div>
                            <ul className="nav navbar-nav align-items-center ml-auto">
                                <li className="nav-item dropdown dropdown-user">
                                    <a className="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" href="javascript:void(0);" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <div className="user-nav d-sm-flex d-none"><span className="user-name font-weight-bolder">{getCookieConsentValue('userName')}</span><span className="user-status">Admin</span></div>
                                        {getCookieConsentValue('photo') === 'null' || getCookieConsentValue('photo') === undefined || getCookieConsentValue('photo') === null || getCookieConsentValue('photo') === "undefined" ?
                                            <img className="round" src={PHOTO} alt="avatar" height="40" width="40"/> :
                                            <img className="round" src={getCookieConsentValue('photo')} alt="avatar"  height="40" width="40" />
                                        }
                                               
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-user"><a className="dropdown-item" href=""><FeatherIcon icon="user" className="mr-50" /> Profile</a><a className="dropdown-item" href=""><FeatherIcon icon="message-square" className="mr-50"/> Messages</a>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" href=""><FeatherIcon icon="settings" className="mr-50" /> Paramètres</a><a className="dropdown-item" href="" onClick={this.handleDeconnexionClick} ><FeatherIcon icon="power" className="mr-50" /> Déconnexion</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        {/* Navbar-container */}                        
                    </div>
                    {/* Header-Navbar */} 

                    <div className="main-menu-wrapper">
                        <div className="header-navbar navbar-expand-sm navbar navbar-horizontal floating-nav navbar-light navbar-shadow menu-border" role="navigation" data-menu="menu-wrapper" data-menu-type="floating-nav">
                            <div className="navbar-header">
                                <ul className="nav navbar-nav flex-row">
                                    <li className="nav-item mr-auto">
                                        <a className="navbar-brand" href=""><img src={logo} /></a>
                                    </li>
                                    <li className="nav-item nav-toggle"><a className="nav-link modern-nav-toggle pr-0" data-toggle="collapse"><FeatherIcon icon="x" className="d-block d-xl-none text-primary toggle-icon font-medium-4"/></a></li>
                                </ul>
                             </div>
                             <div className="shadow-bottom"></div>
                            <div className="navbar-container main-menu-content" data-menu="menu-container">
                                <ul class="nav navbar-nav" id="main-menu-navigation" data-menu="menu-navigation">
                                    <li className="nav-item" data-menu="dropdown"><a className="nav-link d-flex align-items-center" href=""><FeatherIcon icon="layers"/><span className="text" data-i18n="verifAirportTicket"><Nav.Item><Nav.Link eventKey="/verifAirportTicket" href="/verifAirportTicket">Vérification des billets d'avions</Nav.Link></Nav.Item></span></a>                                       
                                    </li> 
                                    
                                    <li className="nav-item" data-menu="dropdown"><a className="nav-link d-flex align-items-center" href="" ><FeatherIcon icon="check-square"/><span className="text" data-i18n="verifID"><Nav.Item><Nav.Link eventKey="/verifID" href="/verifID">Vérification des pièces d'identité</Nav.Link></Nav.Item></span></a>
                                    </li>  

                                    <li className="nav-item" data-menu="dropdown"><a className="nav-link d-flex align-items-center" href="" ><FeatherIcon icon="user-check" /><span className="text" data-i18n="searchUser"><Nav.Item>
                                        <Nav.Link eventKey="/searchUser" href="/searchUser">Obtenir des infos sur un utilisateur</Nav.Link>
                                    </Nav.Item></span></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>




                </div>
                {/* End Header menu */}


                    
                </Fragment>    
            );
         } 
}

export default withRouter(Header);