import axios from 'axios';
import React, { Component, Fragment } from 'react';
import {Helmet} from 'react-helmet';
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import logo from './../../assets/img/logo/MonKolisa.png';
import './../../assets/css/login.css';
import { withRouter } from 'react-router-dom';
import { CONNEXION_QUERY } from '../../query/queryauthent';
import { CONNEXION_URL } from '../../Url';
import Amplify, { Auth, Hub } from 'aws-amplify';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';
class Connexion extends Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			mdp: '',
			msgAlertBody: '',
			msgAlertHeader: '',
			alertVariant: 'danger',
			showAlert: false,
			items: [],
			isLoading: true,
			user: null,
			customState: null,
			userSession: null,
		};

		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handleMDPChange = this.handleMDPChange.bind(this);
		this.handleMaintainSession = this.handleMaintainSession.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleCloseAlert = this.handleCloseAlert.bind(this);
	}

	componentDidMount() {
		Hub.listen("auth",({ payload: { event, data } }) =>  {
			switch (event) {
				case "signIn":
					this.setState({ user: data});
					
					//Auth.currentSession().then(user => console.log(user.idToken.jwtToken))
					Auth.currentSession().then(user => {
						Cookies.set('idToken', user.idToken.jwtToken);
						Cookies.set('accessToken', user.accessToken.jwtToken);
						Cookies.set('refreshToken', user.refreshToken.jwtToken);
						Cookies.set('email', user.idToken.payload.email);
						Cookies.set('userConfirmed', user.idToken.payload.email_verified)
						Cookies.set('userSub', user.idToken.payload.sub)
						Cookies.set('provider', user.idToken.payload.identities[0].providerName)
					})
					Cookies.set('userName', this.state.user.username);
					Cookies.set('street_address', "")
					Cookies.set('locality', "")
					Cookies.set('region', "")
					Cookies.set('postal_code', "")
					Cookies.set('country', "")
					
					// 
					// Cookies.set('name', this.state.user.CognitoUser.user.name)
					// Cookies.set('family_name', this.state.user.CognitoUser.user.family_name)
					// Cookies.set('given_name', this.state.user.CognitoUser.user.given_name)
					// Cookies.set('phone_number', this.state.user.CognitoUser.user.phone_number)
					// Cookies.set('photo', this.state.user.CognitoUser.user.picture)
					break;
				case "signOut":
					this.setState({ user: null });
					break;
				case "customOAuthState":
					this.setState({ customState: data });
			}
		});

		Auth.currentAuthenticatedUser()
			.then(user => this.setState({ user }))
			.catch(() => console.log("Not signed in"));

	}

	handleMaintainSession(event) {

	}


	handleSubmit(event) {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.stopPropagation();
		}
		else {
			this.setState(() => ({
				validated: true
			}));
			var VAR = JSON.stringify({
				"username": this.state.email,
				"password": this.state.mdp
			})
			this.getConnexion(CONNEXION_QUERY, VAR)
		}

	}

	handleCloseAlert(event) {
		this.setState({ showAlert: false });
	}


	handleEmailChange(event) {
		this.setState({ email: event.target.value });
	}


	handleMDPChange(event) {
		this.setState({ mdp: event.target.value });
	}


	getConnexion = async (query, variables) => {
		try {
			const response = await axios.post(CONNEXION_URL, {
				query,
				variables
			});
			// Log the response so we can look at it in the console
			console.log(response.data)

			// Set the data to the state
			this.setState(() => ({
				isLoaded: true,
				items: response.data.data
			}));

			if (this.state.items.loginUser.status === 200) {
				Cookies.set('idToken', this.state.items.loginUser.authentificated_user.idTtoken);
				Cookies.set('accessToken', this.state.items.loginUser.authentificated_user.accessToken);
				Cookies.set('userName', this.state.items.loginUser.user.given_name)


				Cookies.set('userSub', this.state.items.loginUser.user.userSub)
				Cookies.set('userConfirmed', this.state.items.loginUser.user.userConfirmed)
				Cookies.set('name', this.state.items.loginUser.user.name)
				Cookies.set('family_name', this.state.items.loginUser.user.family_name)
				Cookies.set('email', this.state.items.loginUser.user.email)
				Cookies.set('gender', this.state.items.loginUser.user.gender)
				Cookies.set('given_name', this.state.items.loginUser.user.given_name)

				if (this.state.items.loginUser.user.address !== null) {
					Cookies.set('street_address', this.state.items.loginUser.user.address.street_address)
					Cookies.set('locality', this.state.items.loginUser.user.address.locality)
					Cookies.set('region', this.state.items.loginUser.user.address.region)
					Cookies.set('postal_code', this.state.items.loginUser.user.address.postal_code)
					Cookies.set('country', this.state.items.loginUser.user.address.country)
				} else {
					Cookies.set('street_address', "")
					Cookies.set('locality', "")
					Cookies.set('region', "")
					Cookies.set('postal_code', "")
					Cookies.set('country', "")
				}
				Cookies.set('phone_number', this.state.items.loginUser.user.phone_number)
				Cookies.set('city_of_residence', this.state.items.loginUser.user.city_of_residence)
				Cookies.set('description', this.state.items.loginUser.user.description)
				Cookies.set('birthdate', this.state.items.loginUser.user.birthdate)
				Cookies.set('additional_infos', this.state.items.loginUser.user.additional_infos)
				Cookies.set('contrib_cwf_balance', this.state.items.loginUser.user.contrib_cwf_balance)

				Cookies.set('photo', this.state.items.loginUser.user.picture)
				this.props.history.push({
					pathname: '/searchUser',
					state: { items: this.state.items }
				});
			} else {
				//alert('Email et/ou mot de passe incorrect ! Veuillez verifier les informations saisies')
				this.setState(() => ({
					showAlert: true,
					msgAlertBody: 'Email et/ou mot de passe incorrect ! Veuillez verifier les informations saisies',
					msgAlertHeader: 'Echec Connexion',

				}));
			}

		} catch (error) {
			// If there's an error, set the error to the state
			this.setState(() => ({ error }))
		}
	}

	render() {
		const { user } = this.state;
		return (
			<Fragment>
				<Helmet>
					<title>Admin connexion | Monkolis</title>
				</Helmet>
				<div className="container-fluid">
					<div className="row">
						<div className="auth-wrapper auth-main">
							<div className="auth-inner">
								<div className="card">
									<div className="card-body">
										<a className="brand-logo" href="/">
											<img alt="MonKolis" className="img-fluid" src={logo} />
										</a>
										<h4 className="card-title">Bienvenue sur MonKolis 👋</h4>
										<p className="card-text mar-bot15">Entrez un email & mot de passe valide</p>
										{this.state.showAlert ?
											<Alert variant={this.state.alertVariant} onClose={this.handleCloseAlert} dismissible>
												<Alert.Heading>{this.state.msgAlertHeader}</Alert.Heading>
												<p>{this.state.msgAlertBody} </p>
											</Alert> : ''}

										<Form className="well" validated={this.state.validated} onSubmit={this.handleSubmit}>

											{/* <div className="form-group has-feedback">
													<input type="email" className="form-control" placeholder="Email" value={this.state.email} onChange={this.handleEmailChange} />
													<span className="glyphicon glyphicon-envelope form-control-feedback"></span>
												</div> */}

											<Form.Group controlId="validationCustom01">
												<Form.Label>Email</Form.Label>
												<Form.Control
													required
													type="email"
													placeholder="john@exemple.com"
													defaultValue={this.state.email}
													onChange={this.handleEmailChange}
												/>
												<span className="glyphicon glyphicon-envelope form-control-feedback"></span>
												{/* 	<Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
											</Form.Group>

											<Form.Group controlId="validationCustom01">
												<Form.Label>Mot de passe</Form.Label>
												<Form.Control
													required
													type="password"
													placeholder="entrez un mot de passe"
													defaultValue={this.state.mdp}
													onChange={this.handleMDPChange}
												/>
												<span className="glyphicon glyphicon-lock form-control-feedback"></span>
												{/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
											</Form.Group>

											<div className="form-group">
												<div className="custom-control custom-checkbox">
													<div className="d-flex justify-content-between">
														<input type="checkbox" id="session-active" className="custom-control-input" onChange={this.handleMaintainSession} />
														<label htmlFor="session-active" className="custom-control-label" >Garder ma session active</label>
														{/* <a href="/password-lost" className="link"><small>Mot de passe oubié ?</small></a> */}
													</div>
												</div>
											</div>

											<button type="submit" className="btn btn-primary btn-block mar-bot15" value="Connexion" >Connexion </button>
										</Form>

										{/* <p className="mar-top20">Je n'ai pas encore de compte, <a href="/register" className="link color-blue">je m'inscris </a></p> */}

									</div>

								</div>
							</div>

						</div>
					</div>
				</div>
			</Fragment>

		);
	}
}

export default withRouter(Connexion);
