import React, { Component } from 'react';
import './../../assets/css/multiple.css';
import './../../assets/css/index.css';
import './../../assets/css/modal.css';
import logo from './../../assets/img/logo/logo2.png';
import {
    FacebookShareButton,
    FacebookShareCount,
    FacebookIcon,
} from "react-social-buttons";

import ReactFBLike from 'react-fb-like';
class Footer extends Component {

    render() {
        return (
            <footer className="section-footer">
                <div className="container-fluid container-fluid-pad">
                    <div className="row">
                        <div className="align-center mar-top20 mar-bot20 reso col-lg-12">
                            <p>MonKolis offre un service dans l’esprit de l’économie collaborative dont le but est de faire des économies au quotidien tout en contribuant au développement durable.
                                <br />Le transport de kolis à l'international n'aura jamais été aussi simple : nous mettons en relation des transporteurs et des expéditeurs qui veulent envoyer ou recevoir un kolis du pays. Grâce à notre service, le kolis de l'expéditeur arrive rapidement et en toute sureté tandis que le transporteur rentabilise son voyage et fait de belles rencontres. Tout le monde y gagne : les uns payent moins cher leur envoi et les autres remboursent une partie de leurs frais de transport ou augmentent leurs revenus, tout en rendant service.
                            </p>
                        </div>
                    </div>
                    <div className="logo">
                        <p className=""><img src={logo} alt="Logo MonKolis" /></p>
                    </div>
                    <div className="pad-bot20 mar-bot0 pad-top5">
                        <p className="copyright align-center mar-bot0">© 2021 - MonKolis BackOffice | 0.0.1</p>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;