export const SEARCH_USER_INFOS_QUERY = `query ($search_user: SearchUserInput!) {
    searchUsers(search_user: $search_user, limit: 60) {
      status
      message
      users {
        Username
        Attributes {
          Name
          Value
        }
        UserCreateDate
        UserLastModifiedDate
        Enabled
        UserStatus
        MFAOptions {
          AttributeName
          DeliveryMedium
        }
      }
      PaginationToken
      errors{
        param
        msg
      }
    }
  }
`